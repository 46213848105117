<template>
    <v-container fluid style="padding-bottom: 100px;">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Mill</h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mill"
                                        :items="mills"
                                        item-value="mill_id"
                                        :item-text="item => item.mill_id +' - '+ item.mill_name.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        @change="(event) => getMachType(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Machine Type </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_type"
                                        :items="mach_types"
                                        item-value="mach_type"
                                        :item-text="item => item.mach_type +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        @change="(event) => [getMachID(event), getMaintenanceSection(event)]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Machine ID </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="mach_id"
                                        :items="mach_ids"
                                        item-value="mach_id"
                                        :item-text="item => item.mach_id +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        @change="(event) => getMaintenanceSection(event)"
                                        :loading="loading_section"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Maint. Section </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="main_section"
                                        :items="main_sections"
                                        item-value="section_id"
                                        :item-text="item => item.section_id +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        :disabled="disable_sectid"
                                        :loading="isLoadingMainSection"
                                        @change="(event) => getPartMachine(event)"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Part List </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="part_mach"
                                        :items="part_machs"
                                        item-value="part_id"
                                        :item-text="item => item.part_id +' - '+ item.descr.trim()"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="2" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Maintain By </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="maintainBy"
                                        :items="maintainBys"
                                        item-value="maintained_by"
                                        :item-text="item => item.maintained_by"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="1" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Periode</h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="periode"
                                        :items="periodes"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="1" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Checked</h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="filter_empty"
                                        :items="filter_empties"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col xs="6" sm="6" md="1" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Out of Range</h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="out_of_range"
                                        :items="out_of_ranges"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">Start Date </h6>
                                    <v-menu
                                        ref="modal_request"
                                        v-model="modal_request"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date"
                                            label="Start Date"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            no-title
                                            @input="[modal_request = false]"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-6" sm="6" md="2">
                                    <h6 class="blue-lcd mb-2" style="font-weight:600">End Date </h6>
                                    <v-menu
                                        ref="modal_request"
                                        v-model="modal_end"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_to"
                                            label="End Date"
                                            persistent-hint
                                            append-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_to"
                                            no-title
                                            @input="[modal_end = false]"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col xs="12" sm="6" md="1" class="col-6">
                                    <h6 class="blue-lcd" style="font-weight:600">Shift </h6>
                                    <v-autocomplete
                                        solo
                                        dense
                                        clearable
                                        v-model="shift"
                                        :items="shifts"
                                        item-value="value"
                                        :item-text="item => item.text"
                                        class="border-12 ma-0 pa-0 "
                                        hide-details=true
                                        :disabled="disabled_shift"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="4" class="col-12">
                                    <v-btn class="mt-7 border-12 ml-2 mr-2" color="secondary" @click="[clear()]"><v-icon class="mr-1">mdi-backspace</v-icon>Clear</v-btn>
                                    <v-btn class="mt-7 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="fetchData()"><v-icon class="mr-1">mdi-magnify</v-icon>Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0" >
                    <v-card-text class="mt-0">
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="maintenances"
                            class="elevation-1 mt-3"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :loading="$store.state.overlay"
                            height="400"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :search="search" 
                            :item-class="tr_datatable"
                            group-by="part_of_mach_descr"
                            show-group-by
                        >    
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                    <div class="d-flex w-100">
                                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        small
                                        color="#fff"
                                        class="py-5 mr-3"
                                        @click="fetchData()"
                                        >
                                            <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                        <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 mr-3 text-white"
                                        >
                                            <v-icon>mdi-file-excel</v-icon>
                                            <download-excel
                                                class="text"
                                                :fetch           = "exportExcel"
                                                :fields="headersColumn"
                                                :before-generate = "startDownload"
                                                :before-finish   = "finishDownload">
                                                Export Excel
                                            </download-excel>
                                        </v-btn>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.min`]="{ item }">
                                {{ item.mst_maintenance_data_type === 'NUMERIC' ? $store.getters.convertToCurrencyUs(item.min) : '-' }}
                            </template>
                            <template v-slot:[`item.max`]="{ item }">
                                {{ item.mst_maintenance_data_type === 'NUMERIC' ? $store.getters.convertToCurrencyUs(item.max) : '-' }}
                            </template>
                            <template v-slot:[`item.trans_date`]="{ item }">
                                {{ (new Date(new Date(item.trans_date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                            </template> 
                            <!-- <template v-slot:[`item.amt_item`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.amt_item) }}
                            </template> 
                            <template v-slot:[`item.qty`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.qty) }}
                            </template> 
                            <template v-slot:[`item.wgt`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.wgt) }}
                            </template> 
                            <template v-slot:[`item.amt_item`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.amt_item) }}
                            </template>  -->
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"
import { Money } from 'v-money'

export default {
    components: {
        Money
    },
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Maintenance',
                    disabled: true,
                },
                {
                    text: 'Maintenance Report',
                    disabled: true,
                }
            ],
            mills:[],
            mill: 'SR        ',
            maintainBy:'',
            maintainBys:[],
            mach_type: '',
            mach_types: [],
            mach_id: '',
            mach_ids: [],
            main_section: '',
            main_sections: [],
            part_mach: '',
            part_machs: [],
            mst_maintenance: '',
            mst_maintenances: [],
            periodes: [
                {
                    text: 'DAY',
                    value: 'DAY'
                },
                {
                    text: 'SHIFT',
                    value: 'SHIFT'
                }
            ],
            periode: '',
            modal_request: false,
            modal_end: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date_to: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            shifts: [
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                }
            ],
            shift: '',
            disabled_shift: false,
            details: [],
            maintenances: [],
            headers:[
                { text: 'Part List', value: 'part_of_mach_descr' , align:'left'},
                { text: 'TR ID', value: 'tr_id' , align:'left',width:'150', groupable: false},
                { text: 'Item', value: 'seq_no' , align:'left',width:'100', groupable: false},
                { text: 'Trans Date', value: 'trans_date' , width:120, align:'left', groupable: false},
                { text: 'Mach Type', value: 'mash_type_desc' , align:'left', groupable: false},
                { text: 'Mach ID', value: 'mach_id_descr' , align:'left', groupable: false},
                { text: 'Section', value: 'maint_section_descr' , align:'left', groupable: false},
                { text: 'Part ID', value: 'part_id' , align:'left', groupable: false},
                { text: 'Check Descr', value: 'mst_maintenance_check_descr' , align:'left', groupable: false},
                { text: 'Remark', value: 'remark' , align:'left', groupable: false},
                { text: 'Period', value: 'period' ,width:100, align:'left', groupable: false},
                { text: 'Shift', value: 'shift_id' , width:100, align:'left', groupable: false},
                { text: 'By', value: 'maintained_by' , align:'left', groupable: false},
                { text: 'Min', value: 'min',width:'100' , align:'right', groupable: false},
                { text: 'Max', value: 'max',width:'100' , align:'right', groupable: false},
                { text: 'Result', value: 'check_result' , align:'left', groupable: false},
                { text: 'Remark Detail', value: 'remark_detail' , align:'left', groupable: false}
            ],
            headersColumn: {
                'TR ID': 'tr_id',
                'Trans Date': 'trans_date',
                'Machine Type': 'mash_type_desc',
                'Machine ID': 'mach_id_descr',
                'Section': 'maint_section_descr',
                'Part Id': 'part_id',
                'Part List': 'part_of_mach_descr',
                'Group Part': 'mst_maintenance_group_part',
                'Check Descr': 'mst_maintenance_check_descr',
                'Remark': 'remark',
                'Period': 'period',
                'Shift': 'shift_id',
                'By': 'maintained_by',
                'Min':'min',
                'Max':'max',
                'Result': 'check_result',
                'Remark Check List': 'remark_detail',
                'Out of Range': 'out_of_range'
            },
            disable_sectid: false,
            isLoadingMainSection: false,
            search: '',
            loading_section:false,
            filter_empty: 'all',
            filter_empties: [
                {
                    text: 'All',
                    value: 'all'
                },
                {
                    text: 'Yes',
                    value: 'Y'
                },
                {
                    text: 'No',
                    value: 'N'
                }
            ],
            out_of_range: 'all',
            out_of_ranges: [
                {
                    text: 'All',
                    value: 'all'
                },
                {
                    text: 'Yes',
                    value: 'Y'
                },
                {
                    text: 'No',
                    value: 'N'
                }
            ],
            isMobile: false
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.isMobileFunc()
        await this.getMill()
        await this.getMachType('SR')
        await this.getMaintainBy()
        // await this.getMachID('SR')
        // await this.getMaintenanceSection('SR')
        // await this.getPartMachine('SR')
        // await this.getEnvConf()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
        var rowClass = '';

        if (item.mst_maintenance_data_type === 'NUMERIC' && (item.check_result <= Number(item.min) || item.check_result >= Number(item.max))) {
            rowClass = 'out_of_range';
        } else if (item.check_result.trim() === "") {
            rowClass = "jika_check_kosong";
        } else {
            rowClass = 'tr_datatable';
        }

        return rowClass;
        },
        isMobileFunc() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        clear(){
            this.mach_id = ''
            this.main_section = ''
            this.part_mach = ''
            this.periode = ''
            this.date = ''
            this.date_to = ''
            this.shift = ''
            this.mst_maintenances = []
        },
        async getEnvConf(){
            const respVarSectID = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=SR&appl_id=WEBSR&var_id=MAINTSECT&groupid=${this.$store.state.sr.group_id}`, null, false, false, false) 

            if (respVarSectID.data.data != null) {
                var arrayrespVarSectID = respVarSectID.data.data.split(",");

                if (arrayrespVarSectID.length > 1) {
                    var arrayReusable = []
                    for (let index = 0; index < arrayrespVarSectID.length; index++) {
                        const element = arrayrespVarSectID[index];
                        const arrayIndex = this.main_sections.findIndex(x => x.section_id === element);
                        arrayReusable.push(this.main_sections[arrayIndex]);
                    }

                    this.main_sections = arrayReusable
                    this.envExist = true

                } else {
                    this.main_section = respVarSectID.data.data
                    this.disable_sectid = true
                    this.envExist = true
                    await this.getPartMachine(this.main_section)
                }
                
            } else {
                this.main_section = ''
                this.disable_sectid = false
            }
        },
        async getMill(){
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mill?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.mills = respData.data.data
            }
        },
        async getMaintainBy(){
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance/maintained-by`, null, false, false, false) 
            if (respData.status === 200) {
                this.maintainBys = respData.data.data
            }
        },
        async getMachType(event){
            if (event === '' || event === null) {
                this.mach_type = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-type?mill_id=${this.mill ? this.mill.trim() : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_types = respData.data.data
                }
            }
        },
        async getMachID(event){
            if (event === '' || event === null) {
                this.mach_id = ''
            } else {
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/mach-id?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.mach_ids = respData.data.data
                }
            }
        },
        async getMaintenanceSection(event){
            if (event === '' || event === null) {
                this.main_section = ''
            } else {
                this.loading_section = true
                this.main_sections = []
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/maint-section?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&mach_id=${this.mach_id ? this.mach_id : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.loading_section = false
                    this.main_sections = respData.data.data
                    await this.getEnvConf()
                }
            }
        },
        async getPartMachine(event){
            if (event === '' || event === null) {
                this.part_mach = ''
            } else {
                this.isLoadingMainSection = true
                this.part_machs = []
                const respData = await backendApi.fetchCore(`/v2/sr/maintenance/part-of-mach?mill_id=${this.mill ? this.mill.trim() : ''}&section_id=${this.main_section ? this.main_section : ''}`, null, false, false, false) 
                if (respData.status === 200) {
                    this.part_machs = respData.data.data
                    this.isLoadingMainSection = false
                }
            }
        },
        async fetchData(){
            this.$store.dispatch('setOverlay', true)
            if (this.envExist && this.main_section == '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your Maint Section',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                return false
            }
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance?mill_id=${this.mill ? this.mill.trim() : ''}&maintained_by=${this.maintainBy ? this.maintainBy : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&mach_id=${this.mach_id ? this.mach_id : ''}&section_id=${this.main_section ? this.main_section.trim() : ''}&part_id=${this.part_mach ? this.part_mach.trim() : ''}&period=${this.periode ? this.periode : ''}&shift_id=${this.shift ? this.shift : ''}&trans_date=${this.date ? (new Date(new Date(this.date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : ''}&end_date=${this.date_to ? (new Date(new Date(this.date_to) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : ''}&filter_empty=${this.filter_empty ? this.filter_empty : ''}&out_of_range=${this.out_of_range ? this.out_of_range : ''}`, null, false, false, false) 
            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                this.maintenances = respData.data.data
            }
        },
        async exportExcel(){
            if (this.envExist && this.main_section == '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Please fill your Maint Section',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                return false
            }
            const respData = await backendApi.fetchCore(`/v2/sr/maintenance?mill_id=${this.mill ? this.mill.trim() : ''}&mach_type=${this.mach_type ? this.mach_type : ''}&mach_id=${this.mach_id ? this.mach_id : ''}&section_id=${this.main_section ? this.main_section.trim() : ''}&part_id=${this.part_mach ? this.part_mach.trim() : ''}&period=${this.periode ? this.periode : ''}&out_of_range=${this.out_of_range ? this.out_of_range : ''}&shift_id=${this.shift ? this.shift : ''}&filter_empty=${this.filter_empty ? this.filter_empty : ''}&end_date=${this.date_to ? (new Date(new Date(this.date_to) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : ''}&trans_date=${this.date ? (new Date(new Date(this.date) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) : ''}`, null, false, false, false)
            if (respData.status === 200) {
                return respData.data.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    
}
</script>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}

.out_of_range{
  background-color: #FF5252 !important;
  color: #fff;
}
.jika_check_kosong{
  background-color: #def196 !important;
  color: #2c2c2c;
}
.tr_row_y{
  background-color: #c6d5d6 !important;
  color: #2c2c2c;
}
</style>